/* eslint-disable max-len */
import React, { FC } from 'react';

import logo from '../../images/logo.jpg';

const Logo: FC<{
  color?: string;
  colorSecondary?: string;
  height?: number;
  width?: number;
}> = function ({
  color = '#2B2D25',
  colorSecondary = '#2B2D25',
  height = 96,
  width = 463,
}) {
  return <img alt="Modern Eye Gallery" className="h-[46px]" src={logo} />;
};

export default Logo;
