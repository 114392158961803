import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Logo from '../components/Logo';
import colors from '../styles/colors';

const PageMaintenance: FC<PageProps> = function () {
  return (
    <div className="flex flex-col min-h-home">
      <div className="flex justify-center mt-3">
        <Logo
          color={colors.neutral[700]}
          colorSecondary={colors.neutral[500]}
          height={48}
          width={230}
        />
      </div>

      <div className="flex-1 flex justify-center items-center flex-col">
        <h1 className="text-2xl mb-12">Maintenance</h1>
        <div className="max-w-screen-md mx-6">
          <p>
            Le site est en cours de maintenance et est indisponible pour le
            moment.
          </p>
          <p>
            Si vous avez une question, n'hésitez pas à nous appeler au +33 6 12
            37 55 55.
          </p>
          <p>L'équipe de Barrois Antiques</p>
        </div>
      </div>
    </div>
  );
};

export default PageMaintenance;
